import { request } from "@/api/request";
import { downloadGet } from "./downloadData";

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request.post(`/sysDictData/findList`, { dictType: dictType });
}

// 单个文件查询
export function getFileInfo(data) {
  return request.get(`/file/${data}`);
}

// 获取当前登录用户view列表
export function getViewList(data) {
  return request.get(`/ark/org-view`, data);
}

// 获取当前登录用户基础信息
export function getUserInfo(data) {
  return request.get(`/ark/org-userInfo`);
}

//Ocr文件识别-IREC
export function postOcrLog(data) {
  return request.post(`/ocrLog/ocr`, data);
}

// 鉴权-获取视图列表
export function getAuthViewList(data) {
  return request.get(`/auth/org-view`, data);
}

// 鉴权-获取实体树列表
export function getAuthViewEntityList(data) {
  return request.get(`/auth/org-view/entity`, data);
}

// 根据字典类型查询因子库字典数据信息
export function getFactorDicts(dictType) {
  return request.post(`/tFactorDictData/findList`, { dictType: dictType });
}
