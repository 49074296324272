import { Message} from 'element-ui';
var dou_timeout = null;//防抖
//全局请求
import {getUserInfo,getAuthViewList} from '@/api/common';

export default {
  /**
   * 当前年月日时分秒周
   * 返回值：对象格式。 除了周，其余个位补0
   * {D: "11",M: "08",W: "03",Y: "2021",h: "11",m: "36",s: "20"}
   * **/
  getDayInfo() {
    let mydate = new Date();
    let year = mydate.getFullYear();
    let month = mydate.getMonth();
    let date = mydate.getDate();
    let week = mydate.getDay(); //周
    let hour = mydate.getHours();
    let minutes = mydate.getMinutes();
    let seconds = mydate.getSeconds();
    let dayObj = {
      Y: year + '',
      M: (month + 1) < 10 ? ('0' + (month + 1)) : (month + 1) + '',
      D: date < 10 ? ('0' + date) : date + '',
      W: week < 10 ? ('0' + week) : week + '',
      h: hour < 10 ? ('0' + hour) : hour + '',
      m: minutes < 10 ? ('0' + minutes) : minutes + '',
      s: seconds < 10 ? ('0' + seconds) : seconds + '',
    }
    return dayObj;
  },
  /**
   * 默认保留两位小数，去掉末尾0
   * **/
  formatValue2(value,len=2) {
    if (isNaN(value)) {
      return '';
    }
    value = Number(value);
    return parseFloat(value.toFixed(len))
  },
  //字典表文字回显
  getDictLabel(list,value){
    for(let x of list){
      if(x.value == value){
        return x.label;
      }
    }
  },
  //字典表文字回显，支持多选项回显
  getDictLabelArray(list,value){
    let text = '';
    if (!value) {
      return text;
    }
    let textArray = [];
    let valueArr = value.split(",");
    for (let val of valueArr) {
      if (!val) {
        continue;
      }
      for (let x of list) {
        if(x.value == val) {
          textArray.push(x.label);
        }
      }
    }
    return textArray.join(",");
  },
  //防抖
  debounce(fn, wait=500){
    return function(){
        clearTimeout(dou_timeout)
        dou_timeout = setTimeout(()=>{
            fn.apply(this, arguments)
        },wait)
    }
  },
  //select-多选悬浮提示内容
  getSelectTip(option,selectVal){
    var tipText = '';
    if(selectVal){
      for(let i in selectVal){
          for(let y of option){
            if(y.value == selectVal[i]){
              if(tipText.length != 0){
                tipText += '、';
              }
              tipText += y.label;
            }
          }
      }
    }
    return tipText;
  },
  //获取重复数据
  findDuplicates(arr) {
    let map = new Map();
    let duplicates = [];

    for (let i = 0; i < arr.length; i++) {
      if (map.has(arr[i]) && !duplicates.includes(arr[i])) {
        duplicates.push(arr[i]);
      } else {
        map.set(arr[i], true);
      }
    }

    return duplicates;
  },
  //请求用户信息
  async axiosUserInfo(){
    var userInfo = null;
    await getUserInfo().then((res)=>{
      if(res.code == 200){
        userInfo = {...res.data};
      }
    })
    return userInfo;
  },
  //请求鉴权-获取视图列表,viewType:（''- 全部、0-顶级节点（市级）、1-非顶级节点（区县乡镇）
  async axiosGetList(viewType=''){
    var userInfo = await this.axiosUserInfo();
    if(userInfo === null){
      Message.error('获取用户信息失败！');
      return false;
    }
    var viewList = null;
    await getAuthViewList({viewType: viewType}).then((res)=>{
      if(res.code == 200){
        viewList = res.data;
      }
    }).catch(listError=>{
      console.error("error",listError)
    })
    return {...userInfo,viewList:viewList};
  }
}

