<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
export default {
  name: "App",
  provide(){
    return{
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive:true,
    };
  },
  methods:{
    reload(){
      this.isRouterAlive= false;
      this.$nextTick(()=>{
        this.isRouterAlive = true;
      })
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
