export default {
    mg: {
        closeAll: 'CloseAll',
        openAll: 'OpenAll',
        selectYear:'Select Year',
        edit:'Edit',
        delete:'Delete',
        addLine:'Add Line',
        cancel:'Cancel',
        save:'Save',
        emissionSource:'Emission Source',
        unit:'Unit',
        amount:'Amount',
        remark:'Remark',
        factor:'Factor',
        selectEmissionSource:'Please Select EmissionSource',
        pleaseunit:'Please enter a unit',
        pleaseamount:'Please enter the Dosage',
        pleasefactor:'Please enter a factor value',
        pleaseremark:'Please enter a remark value',
        sure:'Sure',
        sureSave:'Are you sure [Save]?',
        tooltip:'Tooltip',
        cannotSwitch:'Editing cannot switch categories',
        CO:'C emission(kgCO2e)',
    },
    view:{
        title:'Total Scope3 Emissions',
        sort:'Category ranking',
        CO:'C emission（tCO2e）',
        total:'Total emissions（%）',
        summaryExport:'SummaryExport',
        detailExport:'DetailExport',
        lowerLevelExport:'LowerLevelExport'
    },
    factor:{
        add:'Add',
        seq:'Seq',
        name:'FactorName: ',
        nameEn:'FactorName(EN)',
        nameZH:'FactorName(ZH)',
        value:'FactorValue',
        op:'Operate',
        year:'Year',
        sureDelete:'confirm to delete?',
        batchImport:'Import',
        batchExport:'Export',
        emissionActivity:'EmissionActivity',
        factorSource:'FactorSource',
        factorFile:'FactorFile',
        area:'Area',
        CO2:'CO₂',
        CH4:'CH₄',
        N2O:'N₂O',
        NF3:'NF₃',
        SF6:'SF₆',
        HFC5:'HFC₅',
        PFC5:'PFC₅',
        emissionValue:'',
        please:'Please Input',
        pleaseNumber:'Please enter a numeric value',
        pleaseNumber2:'Please enter the correct factor value numeric 6 decimal places',
        apiSuccess:"Success",
        apiError:'Api Error',
        templateDownload:'TemplateDownload',
        other:'OtherEmission'
    }
}