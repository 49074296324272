import axios from "axios";
import router from "../router";

axios.defaults.timeout = 300000; //响应时间
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.baseURL = "/gf-api"; //配置接口地址
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    var token = localStorage.getItem("token");
    // var token ='APP_PORTAL_S_CrcmpcnuNRaXKyHazHALEJLzrPE4XBpx'
    var locale = localStorage.getItem("locale");
    if (token) {
      config.headers.token = token;
    }
    if (locale) {
      config.headers.locale = locale;
    }
    return config;
  },
  (error) => {
    console.log("错误的传参");
    return Promise.reject(error);
  }
);

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      // 401 说明 token 验证失败
      // window.location.href=redirect_url;
    }
    console.log("网络异常",error);
    if(error.response.status !==500){
      return Promise.reject(error);
    }
  }
);

const request = {
  /**
   * Restful get api <select>
   * @param url Request url
   * @param data Request data parameter
   * @returns {Promise<Object>}
   */
  async get (url, data) {
    try {
      let res = {};
      res = await axios.get(url, { params: data });
      return new Promise((resolve, reject) => {
        resolve(res.data);
      }).catch((err) => {
        console.log("ERROR: " + err);
        return "fail";
      });
    } catch (err) {
      console.log("ERROR: " + err);
      return "fail";
    }
  },
  /**
   * Restful post api <insert>
   * @param url Request url
   * @param data Request data parameter
   * @returns {Promise<Object>}
   */
  async post (url, data) {
    try {
      let res = {};
      res = await axios.post(url, data);
      return new Promise((resolve, reject) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          //  message.success(res);
        }
      }).catch((error) => {
        console.error(error)
        // message.error(error.response.data);
        return "fail";
        // alert(error.response.data);
      });
    } catch (error) {
      // message.error(error.response.data);
      return "fail";
      // alert(error.response.data);
    }
  },
  /**
   * Restful put api <update>
   * @param url Request url
   * @param data Request data parameter
   * @returns {Promise<Object>}
   */
  async put (url, data) {
    try {
      let res = {};
      res = await axios.put(url, data);
      return new Promise((resolve, reject) => {
        resolve(res.data);
      }).catch((err) => {
        console.log("ERROR: " + err);
        return "fail";
      });
    } catch (err) {
      console.log("ERROR: " + err);
      return "fail";
    }
  },
  /**
   * Restful delete api <delete>
   * @param url Request url
   * @param data Request data parameter
   * @returns {Promise<Object>}
   */
  async delete (url, data) {
    try {
      let res = {};
      res = await axios.delete(url, { data });
      return new Promise((resolve, reject) => {
        resolve(res.data);
      }).catch((err) => {
        console.log("ERROR: " + err);
        return "fail";
      });
    } catch (err) {
      console.log("ERROR: " + err);
      return "fail";
    }
  },
};
export { request };
