//认证状态
export function filterState_auth (val) {
  switch (val) {
    case '未核验':
      return '未核验';
      brreak;
    case '已核验':
      return '已核验';
      brreak;
    case 5:
      return '已撤销';
      brreak;
    case 2:
      return '认证中';
      brreak;
    case 3:
      return '认证成功';
      brreak;
    case 4:
      return '认证失败';
      brreak;
  }
}
//交易状态
export function filterState_trade (val) {
  switch (val) {
    case 0:
      return '暂存';
      brreak;
    case 1:
      return '申请中';
      brreak;
    case 5:
      return '已撤销';
      brreak;
    case 2:
      return '购买中';
      brreak;
    case 3:
      return '购买成功';
      brreak;
    case 4:
      return '购买失败';
      brreak;
  }
}