export default {
    mg: {
        closeAll: '全部折叠',
        openAll: '全部展开',
        selectYear:'选择年份',
        edit:'编辑',
        delete:'删除',
        addLine:'添加一行',
        cancel:'取消',
        save:'保存',
        emissionSource:'排放源',
        unit:'单位',
        amount:'数量',
        remark:'备注',
        factor:'因子值',
        selectEmissionSource:'请选择排放源',
        pleaseunit:'请输入单位',
        pleaseamount:'请输入用量',
        pleasefactor:'请输入因子值',
        pleaseremark:'请输入备注',
        sure:'确定',
        sureSave:'您确定【保存】?',
        tooltip:'提示',
        cannotSwitch:'正在编辑不可以切换分类',
        CO:'碳排放(kgCO2e)',
    },
    view:{
        title:'范围3排放总量',
        sort:'类别排名',
        CO:'碳排放（tCO2e）',
        total:'排放总量（%）',
        summaryExport:'汇总导出',
        detailExport:'明细导出',
        lowerLevelExport:'下级导出'

    },
    factor:{
        add:'新增',
        seq:'序号',
        name:'因子名称: ',
        nameEn:'因子名称(英文)',
        nameZH:'因子名称(中文)',
        value:'因子值',
        op:'操作',
        year:'年份',
        sureDelete:'确定删除吗?',
        batchImport:'批量导入',
        batchExport:'导出全部',
        emissionActivity:'排放活动类型',
        factorSource:'因子来源',
        factorFile:'因子文件',
        area:'区域(国家)',
        CO2:'CO₂排放值',
        CH4:'CH₄排放值',
        N2O:'N₂O排放值',
        NF3:'NF₃排放值',
        SF6:'SF₆排放值',
        HFC5:'HFC₅排放值',
        PFC5:'PFC₅排放值',
        emissionSource:'排放值',
        please:'请输入',
        pleaseNumber:'请输入数字值',
        pleaseNumber2:'请输入正确因子值（数值型6位小数）',
        apiSuccess:"操作成功",
        apiError:'接口调用异常',
        templateDownload:'模板下载',
        other:'其余温室气体排放值'
    }
}