import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//按需引入ant组件
import { Select, Input } from 'ant-design-vue';
import 'ant-design-vue/lib/select/style/css';
Vue.component(Select.name, Select);
Vue.component(Input.name, Input);

import './assets/css/common.scss';
import axios from 'axios';
import * as filters from './filters';
import config from './assets/js/config';
import common from './assets/js/common';
import VueI18n from 'vue-i18n';                              //i18n    
import locale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';        //英文
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';     //中文
import zhMessageScope3 from './assets/locales/zh-CN';
import enMessageScope3 from './assets/locales/en-US';
import _ from 'lodash';
import appPortalSDK from 'enos-app-portal-sdk';
// 字典数据组件
import DictData from '@/components/DictData';
// imgurl
import plugins from './plugins/pluConfig'; // plugins
// 引入echarts4
let echarts = require('echarts');
Vue.prototype.$echarts = echarts;
//获取iframe 多语言
const getIframeQueryString = (name) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const iframe = window.parent.document.getElementsByTagName('iframe')[0];
    if (!iframe) {
        return null;
    }
    var r = iframe.contentWindow.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURI(r[2]);
    }
    return null;
};

let localeS = 'zh';
const searchLocale = getIframeQueryString('locale');
if (searchLocale) {
    localeS = (searchLocale === 'zh-CN' ? 'zh' : 'en');
}
localStorage.setItem('locale', localeS);

appPortalSDK.getUserInfo().then(res => {
    localStorage.setItem('token', res.accessToken);
})
    .catch(res => {
        console.log("失败", res);
    });
Vue.prototype._ = _;
const messages = {
    en: {
        ...enLocale,    //关键字
        ...enMessageScope3
    },
    zh: {
        ...zhLocale,    //关键字
        ...zhMessageScope3
    }
};

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});
Vue.prototype.$axios = axios;
Vue.prototype.$config = config;
Vue.prototype.$common = common;
Vue.config.productionTip = false;
Vue.use(plugins);
Vue.use(VueI18n);
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: localeS,   // 设置默认语言
    messages        // 设置自定义的语言包
});
locale.i18n((key, value) => i18n.t(key, value));
DictData.install();


new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n
}).$mount("#app");
