import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/publicService_public", //重定向
    },
    //欢迎页-公网
    {
        path: "/publicService_public",
        name: "publicService_public",
        meta: {
            name: "中国机电商会光伏产品碳足迹公共服务平台",
        },
        component: () => import("@/pages/other/publicService_public.vue"),
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: [...routes],
});

export default router;
